import React from 'react';
import MisFavoritosList from '../../../components/mis-favoritos/MisFavoritosList';


export default function MisFavoritos({search}) {

    if(!search) return null;

    return (
        <>
                <div className="mt-4 mt-lg-5">
                    <div className="table-responsive">
                        <table className="table align-middle">
                            <thead></thead>
                            <tbody>
                            <MisFavoritosList  favoritos={search} key={search} />
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
        </>
    )

}