import React from 'react';
import { useFormUDP } from '../../hooks/useFormUDP';
import Message from '../messages/message';

/*const initialForm = {
    id: 1,
    nombre:"",
    apellidoPaterno: "",
    apellidoMaterno: "",
    telefono: "",
    direccion: ""
};*/

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.nombre.trim()){
        errors.nombre = "El campo nombre es requerido";
    } else if( !regexNombres.test(form.nombre.trim()) ) {
        errors.nombre = "El campo nombre solo acepta letras y espacios";
    }

    if(!form.apellidoPaterno.trim()){
        errors.apellidoPaterno = "El campo apellido es requerido";
    } else if(!regexNombres.test(form.apellidoPaterno.trim())){
        errors.apellidoPaterno = "El campo apellido solo acepta letras y espacios";
    }

    if(!form.apellidoMaterno.trim()){
        errors.apellidoMaterno = "El campo apellido es requerido";
    } else if(!regexNombres.test(form.apellidoMaterno.trim())){
        errors.apellidoMaterno = "El campo apellido solo acepta letras y espacios";
    }    

    /*if (!form.telefono.trim()){
        errors.telefono = "La campo telefono es requerida";
    }

    if (!form.direccion.trim()){
        errors.direccion = "La campo direccion es requerida";
    }*/

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

export default function FormDatosPersonales({datos}) {
    
    const initialForm = {
        id: 1,
        nombre: datos.nombre,
        apellidoPaterno: datos.apellidoPaterno,
        apellidoMaterno: datos.apellidoMaterno,
        telefono: datos.telefono,
        direccion: datos.direccion
    };
    
    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormUDP(initialForm, validationsForms);

    if(!datos) return null;

    return (
        <div className="modal fade" id="datosPersonalesModal" tabIndex="-1" aria-labelledby="datosPersonalesModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit} >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="datosPersonalesModalLabel">Datos Personales</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <label htmlFor="puesto" className="col-form-label">Nombres</label>
                                            <input type="text" className="form-control" id="nombre" name='nombre' onBlur={handleBlur} onChange={handleChange} value={form.nombre} required />
                                            {/*errors.puesto && <p style={styles} > {errors.puesto}</p>*/}
                                        </div>
                                        <div className="col-sm-4">
                                            <label htmlFor="compania" className="col-form-label">Apellido Paterno</label>
                                            <input type="text" className="form-control" id="apellidoPaterno" name='apellidoPaterno' onBlur={handleBlur} onChange={handleChange} value={form.apellidoPaterno} required />
                                            {/*errors.compania && <p style={styles} > {errors.compania}</p> */ }
                                        </div>

                                        <div className="col-sm-4">
                                            <label htmlFor="compania" className="col-form-label">Apellido Materno</label>
                                            <input type="text" className="form-control" id="apellidoMaterno" name='apellidoMaterno' onBlur={handleBlur} onChange={handleChange} value={form.apellidoMaterno} required />
                                            {/*errors.compania && <p style={styles} > {errors.compania}</p>*/}
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                    <div className="col-sm-6">
                                            <label htmlFor="fechafin" className="col-form-label">Teléfono</label>
                                            <input type="text" className="form-control" id="telefono" name='telefono' onBlur={handleBlur} onChange={handleChange} value={form.telefono} required />
                                            {/*errors.fechafin && <p style={styles} > {errors.fechafin}</p>*/}
                                        </div>

                                        <div className="col-sm-6">
                                            <label htmlFor="fechafin" className="col-form-label">Dirección</label>
                                            <input type="text" className="form-control" id="direccion" name='direccion' onBlur={handleBlur} onChange={handleChange} value={form.direccion} required />
                                            {/*errors.fechafin && <p style={styles} > {errors.fechafin}</p>*/}
                                        </div>
                                                                                
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Actualizar</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>

            
    );

}
