import React from 'react';
import swal from 'sweetalert';

let urlPulic = process.env.REACT_APP_URL_PUBLIC;
let urlEndPoint = process.env.REACT_APP_API_URL;

async function deleteFavorito(credentials) {

    try {
        
        const responseFetch = await fetch(`${urlEndPoint}/postulaciones/${credentials}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }
    
}

{/* Texto de  */}
export default function MisFavoritosList({favoritos}) {

    var idEmpleoBase64 = "";
    const user = JSON.parse(localStorage.getItem('user'));
    const idCandidato = user.codeId;

    function routeChange(id) {
        
        let path = urlPulic+`/busqueda-trabajo?empleo=`+id;
        console.log(path);
        //navigate(path);
        window.location.href = path;

        /*window.open(
            path,
            '_blank'
        );*/
    }

   
    const eliminarFavorito = async (a) => {
        //e.preventDefault();
        
        swal({
            title: "¿Estas seguro?",
            text: "Una vez eliminado, ¡no podrá recuperar este item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {

                const response = await deleteFavorito(a);
            
                if ('status' in response) {
                    swal("¡Éxito!", "Se borro correctamente", "success");
                    window.location.href = "/favoritos";
                } else {
                    swal("Error", "Ocurrio un error al eliminar", "error");
                }

            } else {
                //confirmacion = False;
            }
        });
        
    }

    return (
        <>
                {
                    favoritos.map(({id, empleoId},index)=>{
                        
                        idEmpleoBase64 = Buffer.from(empleoId.id.toString()).toString('base64');

                        return <tr key={index}>
                            <td style={{width: "80%"}}>
                                <div className="pxp-candidate-dashboard-experience-title"><a href="#javascript" onClick={() => routeChange(idEmpleoBase64)} > {empleoId.nombre} </a></div>
                                <div className="pxp-candidate-dashboard-experience-school">{empleoId.cargo}</div>
                                </td>
                            <td>
                                <div className="pxp-dashboard-table-options">
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-calendar-heart" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5ZM1 14V4h14v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Zm7-6.507c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
                                    </svg>
                                    
                                    &nbsp; &nbsp;
                                    
                                    <button type="button" className="btn btn-primary" title="Postular" onClick={() => routeChange(idEmpleoBase64)} style={{ width: "auto", height: "auto" }}>Postular</button>

                                    &nbsp; &nbsp;
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16" onClick={() => eliminarFavorito(id)} >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                    
                                </div>
                            </td>
                        </tr>;
                    })
                }
        </>
    );
};