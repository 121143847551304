import React,{useState, useEffect} from 'react';
import swal from 'sweetalert';

let urlEndPoint = process.env.REACT_APP_API_URL;
let urlDomain = process.env.REACT_APP_URL_DOMAIN;
let urlPublico = process.env.REACT_APP_URL_PUBLIC;
let urlFile = process.env.REACT_APP_URL_FILE;

export default function SectionCV() {

    const user = JSON.parse(localStorage.getItem('user'));
    const idCandidato = user.codeId;
    const cvStorage = localStorage.getItem('cv');

    const handleSubmit = async (event) => {
        //setStatus(""); // Reset status
        const myFile = document.querySelector("input[type=file]").files[0];
        event.preventDefault();
        
        if (!myFile) {
            //console.log('image is required');
            alert("La imagen es requerida");
            document.getElementById('file').value = "";
            return false;
        }
        
        if (!myFile.name.match(/\.(pdf|doc|docx)$/)) {
            //console.log('select valid image.');
            alert("La imagen no es valida");
            document.getElementById('file').value = "";
            return false;
        }


        const formData = new FormData();
        formData.append("file", myFile);
        formData.append("nombre", "myFile");
        formData.append("tipo", "doc");
        formData.append("modalidad", "candidatodoc");
        formData.append("codigo", idCandidato);
        //formData.append("name", name);

        const responseFetch = await fetch(`${urlEndPoint}/archivos/upload`, {
            method: 'POST',
            body: formData
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        if ('upload' in responseFetch) {
            swal("¡Éxito!", "Se actualizó correctamente", "success");
            localStorage.setItem('cv', responseFetch['nombre'] );
            window.location.href = "/";
        } else {
            swal("Error", "Ocurrio un error al actualizar", "error");
        }
        
        //setStatus(resp.status === 200 ? "Thank you!" : "Error.");
    };

    const onChangeFile = event => {
        const image = event.target.files[0];
        if (!image) {
            alert("El documento es requerido");
            document.getElementById('file').value = "";
            return false;
        }
        
        if (!image.name.match(/\.(pdf|doc|docx)$/)) {
            alert("El documento no es valido");
            document.getElementById('file').value = "";
            return false;
        }
          
    }

    let cv_print;

    if (cvStorage.length < 5){
        cv_print = ''
    } else{
        cv_print = <div className="col-lg-5 col-xl-4 col-xxl-3"> 
            <h4>Descargar</h4>
            <div className="mt-4 col-xxl-8">
                <a href={`${urlFile}/${cvStorage}`} target="_blank">Clic para descargar</a>
            </div>
        </div>
    } 

    return (
        <div className="pxp-dashboard-content-details">
            <div className="row">
                <div className="col-lg-7 col-xl-8 col-xxl-9">
                    <h1>Subir CV</h1>
                    <div className="mt-4 col-xxl-8">
                        <label className="form-label">Seleccione el archivo a subir (Formato PDF y WORD)</label>
                        <input className="form-control" type="file" id="file" onChange={onChangeFile} />
                    </div>
                    <div className=" mt-4">
                        <button className="btn rounded-pill pxp-section-cta" onClick={handleSubmit} >Guardar CV</button>
                    </div>
                </div>
                {cv_print}
            </div>
        </div>
    )
}