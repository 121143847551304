import React,{useState, useEffect} from 'react';
import swal from 'sweetalert';
import { findExpLaboralByID } from '../../services/apis';
import ExperienciaLaboralModalUp from './ExperienciaLaboralModalUp';

let urlEndPoint = process.env.REACT_APP_API_URL;

async function deleteEventoCV(credentials) {

    try {
        
        const responseFetch = await fetch(`${urlEndPoint}/experiencialaboral/${credentials}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(credentials)
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }
    
}


export default function ExperienciaLaboralList({experiencia}) {

    //const [expLaboralList, setexpLaboralList] = useState(null);
    const [search, setSearch] = useState(null);

    const [idini, setIdIni] = useState(0);
    const [idfinal, setIdFinal] = useState(null);
    

    const eliminar = async (a) => {
        //e.preventDefault();

        swal({
            title: "¿Estas seguro?",
            text: "Una vez eliminado, ¡no podrá recuperar este item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {

                const response = await deleteEventoCV(a);
            
                if ('status' in response) {
                    swal("¡Éxito!", "Se borro correctamente", "success");
                    window.location.href = "/cv";
                } else {
                    swal("Error", "Ocurrio un error al eliminar", "error");
                }

            } else {
                //confirmacion = False;
            }
        });        
        
    }
    

    useEffect( () => {

        console.log("ID: " + idini)
        //setIdFinal(() => idini);

        // if ( (idini === null) ) return;
        // if(!idini) {setexpLaboralList(null); return null};

        async function fetchData() {
            try {

                const expList = await findExpLaboralByID(idini);
                
                console.log(expList);
                setSearch(expList);                

            } catch (e) {
                console.error(e);
            }

            

        };

        fetchData();

    },[idini]);
    


    return (
        <>
            {
                experiencia.map(({id, nombre, compania, descripcion, fechaIngreso, fechaTermino},index)=>{
                    return <tr key={index}>
                            <td style={{width: "30%"}}><div className="pxp-candidate-dashboard-experience-title">{nombre}</div></td>
                            <td style={{width: "25%"}}><div className="pxp-candidate-dashboard-experience-company">{compania}</div></td>
                            <td style={{width: "25%"}}><div className="pxp-candidate-dashboard-experience-time">{fechaIngreso ? fechaIngreso.substring(0,4): ""} - {fechaTermino ? fechaTermino.substring(0,4): ""}</div></td>
                            <td>
                                <div className="pxp-dashboard-table-options">
                                    <ul className="list-unstyled">
                                        <li><button title="Editar" onClick={() => (setIdIni(id)) } data-bs-toggle="modal" data-bs-target="#experienciaLaboralUPModal" ><span className="fa fa-pencil"></span></button></li>
                                        <li><button title="Eliminar" onClick={() => eliminar(id)} ><span className="fa fa-trash-o"></span></button></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                        
                    ;
                })
            }
            <ExperienciaLaboralModalUp search={search} />
        </>
    );
};