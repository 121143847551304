import React from 'react';
import FormFormacionAcademicaUP from './FormFormacionAcademicaUP';

const FormacionAcademicaModalUp = ({search}) => {
    
    if(!search) return null;
    
    return (
        <>
        {<FormFormacionAcademicaUP data={search} />}
        </>
    )
}

export default FormacionAcademicaModalUp;