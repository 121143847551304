import React,{useState, useEffect} from 'react';
import { useFormREP } from '../../hooks/useFormREP';
import { findExpLaboralByID, findExpLaboralByIDCandidato } from '../../services/apis';
import Message from '../messages/message';
import swal from 'sweetalert';
import { useFormRFA } from '../../hooks/useFormRFA';
import { useFormRCH } from '../../hooks/useFormRCH';

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexTextoNumeros = /^[-_ a-zA-ZÑñÁáÉéÍíÓóÚúÜü0-9]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    /*if(!form.puesto.trim()){
        errors.puesto = "El campo puesto es requerido";
    } else if( !regexTextoNumeros.test(form.puesto.trim()) ) {
        errors.puesto = "El campo puesto solo acepta letras y espacios";
    }

    if(!form.compania.trim()){
        errors.compania = "El campo empresa es requerido";
    } else if(!regexTextoNumeros.test(form.compania.trim())){
        errors.compania = "El campo empresa solo acepta letras y espacios";
    }

    if (!form.fechaini.trim()){
        errors.fechaini = "La campo fecha de inicio es requerida";
    }

    if (!form.fechafin.trim()){
        errors.fechafin = "La campo fecha fin es requerida";
    }*/

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}


let urlEndPoint = process.env.REACT_APP_API_URL;

async function updateEvento(e) {

    const user = JSON.parse(localStorage.getItem('user'));
    const GetUserID = user.codeId;

    const id = e.target.id.value;
    //const GetUserID = e.target.id.value;
    const conocimiento = e.target.conocimiento.value;

    try {
                
        const responseFetch = await fetch(`${urlEndPoint}/conocimientoshabilidades/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id : id,
                postulanteId : `${GetUserID}`,
                nombre: `${conocimiento}`,
            })
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }
    
}


const FormConocimientosHabilidadesUP = ({data}) => {
//export default function FormExperienciaLaboralUP({experiencia}){

    console.log("CONOCIMIENTOS HABILIDADES")
    console.log(data);

    const initialForm = {
        id: "",
        conocimiento:""
    };

   const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormRCH(initialForm, validationsForms);

    const cerrar = async (e) => {
            form.id = "";
            form.conocimiento = "";
    }

    const handleSubmitt = async e => {
        e.preventDefault();

        e.currentTarget.disabled = true;

        const response = await updateEvento(e);
        
        if ('id' in response) {
            swal("Actualizado", "Se actualizó la información correctamente", "success", {
                buttons: false,
                timer: 2000,
            })
            .then((value) => {
                window.location.href = "/cv";
            });
        } else {
            swal("Error", "Error al actualizar", "error");
        }
    }
    
    

    return (
        <div className="modal fade" id="conocimientoHabilidadesUPModal" tabIndex="-1" aria-labelledby="conocimientoHabilidadesUPModal" aria-hidden="true">                   
                <form onSubmit={handleSubmitt}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="conocimientoHabilidadesUPModalLabel">Conocimientos y Habilidades</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => cerrar()} ></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="conocimiento" className="col-form-label">Conocimiento o habilidad</label>
                                            <input type="hidden" id="id" name='id'value={form.id ? form.id : data.id} required readOnly />
                                            <input type="text" className="form-control" id="conocimiento" name='conocimiento' onBlur={handleBlur} onChange={handleChange} value={form.conocimiento ? form.conocimiento : data.nombre} required />
                                            {errors.conocimiento && <p style={styles} > {errors.conocimiento}</p>}
                                        </div>
                                        
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => cerrar()} >Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se añadió de manera correcta." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormConocimientosHabilidadesUP;