import React,{useState, useEffect} from 'react';
import { useFormREP } from '../../hooks/useFormREP';
import { findExpLaboralByID, findExpLaboralByIDCandidato } from '../../services/apis';
import Message from '../messages/message';
import swal from 'sweetalert';
import { useFormRFA } from '../../hooks/useFormRFA';

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexTextoNumeros = /^[-_ a-zA-ZÑñÁáÉéÍíÓóÚúÜü0-9]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    /*if(!form.puesto.trim()){
        errors.puesto = "El campo puesto es requerido";
    } else if( !regexTextoNumeros.test(form.puesto.trim()) ) {
        errors.puesto = "El campo puesto solo acepta letras y espacios";
    }

    if(!form.compania.trim()){
        errors.compania = "El campo empresa es requerido";
    } else if(!regexTextoNumeros.test(form.compania.trim())){
        errors.compania = "El campo empresa solo acepta letras y espacios";
    }

    if (!form.fechaini.trim()){
        errors.fechaini = "La campo fecha de inicio es requerida";
    }

    if (!form.fechafin.trim()){
        errors.fechafin = "La campo fecha fin es requerida";
    }*/

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}


let urlEndPoint = process.env.REACT_APP_API_URL;

async function updateEvento(e) {

    const user = JSON.parse(localStorage.getItem('user'));
    const GetUserID = user.codeId;

    const id = e.target.id.value;
    //const GetUserID = e.target.id.value;
    const institucion = e.target.institucion.value;
    const nivelestudios = e.target.nivelestudios.value;
    const carrera = e.target.carrera.value;
    const fechaini = e.target.fechaini.value;
    const fechafin = e.target.fechafin.value;

    try {
                
        const responseFetch = await fetch(`${urlEndPoint}/formacionacademica/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id : id,
                postulanteId : `${GetUserID}`,
                centroEducativo: `${institucion}`,
                nivelEstudios: `${nivelestudios}`,
                areaProfesional: `${carrera}`,
                fechaInicio: `${fechaini}`,
                fechaTermino: `${fechafin}`,
            })
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }
    
}


const FormFormacionAcademicaUP = ({data}) => {
//export default function FormExperienciaLaboralUP({experiencia}){

    const initialForm = {
        id: "",
        carrera:"",
        nivelestudios: "",
        institucion:"",
        fechaini: "",
        fechafin: "",
        descripcion: ""
    };

   const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormRFA(initialForm, validationsForms);

    const cerrar = async (e) => {
            form.id = "";
            form.carrera = "";
            form.nivelestudios = "";
            form.institucion = "";
            form.fechaini = "";
            form.fechafin = "";
            form.descripcion = "";
    }

    const handleSubmitt = async e => {
        e.preventDefault();

        e.currentTarget.disabled = true;

        const response = await updateEvento(e);
        
        if ('id' in response) {
            swal("Actualizado", "Se actualizó la información correctamente", "success", {
                buttons: false,
                timer: 2000,
            })
            .then((value) => {
                window.location.href = "/cv";
            });
        } else {
            swal("Error", "Error al actualizar", "error");
        }
    }
     

    return (
        <div className="modal fade" id="formacionAcademicaUPModal" tabIndex="-1" aria-labelledby="formacionAcademicaUPModal" aria-hidden="true">
                <form onSubmit={handleSubmitt}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="formacionAcademicaUPModalLabel">Formación Academica</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => cerrar()}></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="carrera" className="col-form-label">Carrera profesional</label>
                                            <input type="hidden" id="id" name='id'value={form.id ? form.id : data.id} required readOnly />
                                            <input type="text" className="form-control" id="carrera" name='carrera' onBlur={handleBlur} onChange={handleChange} value={form.carrera ? form.carrera : data.areaProfesional} required />
                                            {errors.carrera && <p style={styles} > {errors.carrera}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="nivelestudios" className="col-form-label">Nivel de estudios</label>
                                            <input type="text" className="form-control" id="nivelestudios" name='nivelestudios' onBlur={handleBlur} onChange={handleChange} value={form.nivelestudios ? form.nivelestudios : data.nivelEstudios} required />
                                            {errors.nivelestudios && <p style={styles} > {errors.nivelestudios}</p>}
                                        </div>

                                        <div className="col-sm-12">
                                            <label htmlFor="institucion" className="col-form-label">Institución</label>
                                            <input type="text" className="form-control" id="institucion" name='institucion' onBlur={handleBlur} onChange={handleChange} value={form.institucion ? form.institucion : data.centroEducativo} required />
                                            {errors.institucion && <p style={styles} > {errors.institucion}</p>}
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="fechaini" className="col-form-label">Fecha de Inicio</label>
                                            <input type="text" className="form-control" id="fechaini" name='fechaini' onBlur={handleBlur} onChange={handleChange} value={form.fechaini ? form.fechaini : data.fechaInicio} required />
                                            {errors.fechaini && <p style={styles} > {errors.fechaini}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="fechafin" className="col-form-label">Fecha de Termino</label>
                                            <input type="text" className="form-control" id="fechafin" name='fechafin' onBlur={handleBlur} onChange={handleChange} value={form.fechafin ? form.fechafin : data.fechaTermino} required />
                                            {errors.fechafin && <p style={styles} > {errors.fechafin}</p>}
                                        </div>
                                        
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => cerrar()}>Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se añadió de manera correcta." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormFormacionAcademicaUP;