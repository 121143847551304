import React from 'react';
import { useFormUSM } from '../../hooks/useFormUSM';
import Message from '../messages/message';


const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.sobremiCargo.trim()){
        errors.sobremiCargo = "El campo es requerido";
    } else if(!form.sobremiDescripcion.trim()){
        errors.sobremiDescripcion = "El campo es requerido";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

export default function FormSobreMi({datos}) {
    
    const initialForm = {
        id: 1,
        sobremiCargo: datos.sobremiCargo,
        sobremiDescripcion: datos.sobremiDescripcion
    };
    
    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormUSM(initialForm, validationsForms);

    if(!datos) return null;

    return (
        <div className="modal fade" id="sobreMiModal" tabIndex="-1" aria-labelledby="sobreMiModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit} >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="sobreMiModalLabel">Sobre Mi</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="recipient-name" className="col-form-label">Cargo representativo</label>
                                            <input type="text" className="form-control" id="sobremiCargo" name='sobremiCargo' onBlur={handleBlur} onChange={handleChange} value={form.sobremiCargo} required />
                                            {errors.sobremiCargo && <p style={styles} > {errors.sobremiCargo}</p>}
                                        </div>
                                        
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label htmlFor="recipient-name" className="col-form-label">Descripción:</label>
                                            <textarea className="form-control" id="sobremiDescripcion" name="sobremiDescripcion" rows="5" onBlur={handleBlur} onChange={handleChange} value={form.sobremiDescripcion} required ></textarea>
                                            {errors.sobremiDescripcion && <p style={styles} > {errors.sobremiDescripcion}</p>}
                                        </div>
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Actualizar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se actualizo correctamente." bgColor="#198754" /> }
            </div>

            
    );

}
