import React from 'react';
import { Routes ,Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import Favoritos from './pages/favoritos/Favoritos';
import MiCV from './pages/miCV/MiCV';
import Configuracion from './pages/configuracion/Configuracion';
import Postulaciones from './pages/postulaciones/Postulaciones';
import Privacidad from './pages/privacidad/Privacidad';
import Login from './pages/login/Login';
import UploadImagen from './pages/uploads/UploadImagen';
import UploadCv from './pages/uploads/UploadCv';

import { useCookies } from 'react-cookie';
import {decode} from 'html-entities';



function App() {

    const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'user']);

    const user = decode(cookies.user);
    const GetUserID = user.codeId;
    
    const ubicacion = window.location.pathname;
    const token = localStorage.getItem('accessToken');
    //console.log("TOKEN: " + token);    

    if(!token) {
        
        if(!GetUserID){
            if(ubicacion == "/login"){

            } else {
                window.location.href = "/login";
            }
        } else {
            localStorage.setItem('accessToken', cookies.accessToken );
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('imagen', user.imagen );
            localStorage.setItem('cv', user.cv );
            window.location.href = "/";
        }
        
    }

    if(!user){
        window.localStorage.clear();
        window.sessionStorage.clear();
            
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("imagen");
        localStorage.removeItem("cv");
    }

    return (
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/favoritos' element={<Favoritos/>} />
        <Route path='/cv' element={<MiCV/>} />
        <Route path='/configuracion' element={<Configuracion/>} />
        <Route path='/postulaciones' element={<Postulaciones/>} />
        <Route path='/privacidad' element={<Privacidad/>} />
        <Route path='/actualizarfoto' element={<UploadImagen/>} />
        <Route path='/actualizarcv' element={<UploadCv/>} />
        <Route path='/login' element={<Login/>} />
      </Routes>
    );
  
}
      
export default App;
