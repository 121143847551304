import React from 'react';
import FormacionList from '../../../components/formacion/FormacionList';
import FormFormacionAcademica from '../../../components/formacion/FormFormacionAcademica';

export default function FormacionAcademica({search}) {

    if(!search) return null;

    return (
        <>
            <div className="mt-4 mt-lg-5">
                <div style={{position: "relative"}}>
                    <div style={{"display": "inline-block"}} >
                        <h2 style={{"float": "left"}}>Formación Académica &nbsp;&nbsp;</h2>
                        <button type="button" title="Añadir" data-bs-toggle="modal" data-bs-target="#formacionAcademicaModal" style={{"float": "left", borderRadius: "5px", backgroundColor: "var(--pxpMainColorLight)", borderWidth: "inherit" }}><span className="fa fa-plus-circle"></span></button>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table align-middle">
                        <thead></thead>
                        <tbody>
                            <FormacionList formacion={search} key={search}/>
                        </tbody>
                        <tfoot></tfoot>
                    </table>
                </div>
            </div>

            <FormFormacionAcademica />
        </>
    )

}