import { useState } from "react";
import { helpHttp } from "../helpers/helpHttp";

let urlEndPoint = process.env.REACT_APP_API_URL;

export const useFormRID = (initialForm, validateForm) => {
    const[form, setForm] = useState(initialForm);
    const[errors, setErros] = useState({});
    const[loading, setLoading] = useState(false);
    const[response, setResponse] = useState(null);

    const user = JSON.parse(localStorage.getItem('user'));
    const GetUserID = user.codeId;

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm({
            ...form,
            [name]:value,
        });
    };
    const handleBlur = (e) => {
        handleChange(e);
        setErros(validateForm(form));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErros(validateForm(form));

        const{id, nombreIdioma, nivelEscrito, nivelOral} = form;

        if(Object.keys(errors).length === 0){
            //alert("Formulario Send");

            let urlAPIendPoint = `${urlEndPoint}/idiomas/`;
            let optionsBody;
            
                optionsBody = {
                    id : null,
                    postulanteId : `${GetUserID}`,
                    nombre: `${nombreIdioma}`,
                    nivelEscritoId: {
                        id: `${nivelEscrito}`
                    },
                    nivelOralId: {
                        id: `${nivelOral}`
                    },
                }
            

            let optionsPars = {
                //headers: { type: 'normal' },
                body: optionsBody
            }

            console.log(optionsPars);

            try {

                helpHttp()
                .post(urlAPIendPoint, optionsPars)
                .then( (res) => {
                    //console.log(res);
                    setLoading(false);
                    setResponse(true);
                    setTimeout(() => setResponse(false), 5000);
                    setForm(initialForm);

                    if ('id' in res) {
                        window.location.href = "/cv";
                    }
                })
                .catch(error => {
                    //this.setState({ errorMessage: error.toString() });
                    //console.log('There was an error!' + error);
                    console.error('There was an error!', error);
                });

                //window.location.href = "/cv/";

            } catch (error) {
                //alert("error");
                console.log('There was an error!' + error);
                console.error(error);
            }
            

        } else {
            return
        }
    };

    return{
        form, 
        errors, 
        loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    }

};