import React from 'react';
import FormOtrosConocimientosUP from './FormOtrosConocimientosUP';


const OtrosConocimientosModalUp = ({search}) => {
    
    if(!search) return null;
    
    return (
        <>
        {<FormOtrosConocimientosUP data={search} />}
        </>
    )
}

export default OtrosConocimientosModalUp;