import React, { useState } from 'react';
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormSobreMi from '../../../components/sobre-mi/FormSobreMi';



export default function SobreMi({search}) {

    //console.log("SOBRE MI");
    //console.log(search);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(!search) return null;

    //console.log("UNO");
    //console.log(search.sobremiDescripcion);

    return (
        <>
            <div style={{position: "relative"}}>
                <div style={{"display": "inline-block"}} >
                    <h1 style={{"float": "left"}} >Sobre Mi &nbsp;&nbsp;</h1>
                    <button type="button" title="Editar" data-bs-toggle="modal" data-bs-target="#sobreMiModal" style={{"margin-top": "4%", "float": "left", borderRadius: "5px", backgroundColor: "var(--pxpMainColorLight)", borderWidth: "inherit" }} ><span className="fa fa-pencil"></span></button>
                </div>
            </div>
            <p><b>{search.sobremiCargo ? search.sobremiCargo : 'Aún no haz añadido tu cargo representativo'}</b></p>
            <p className="pxp-text-light">{search.sobremiDescripcion ? search.sobremiDescripcion : 'Aún no haz añadido tu descripción'}</p>

            <FormSobreMi datos={search} key={search} />

        </>
    )

}