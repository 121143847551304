import React,{useState, useEffect} from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import ConfiguracionEmail from './ConfiguracionEmail';
import ConfiguracionPassword from './ConfiguracionPassword';
import ConfiguracionDosFactores from './ConfiguracionDosFactores';
import ConfiguracionPrivacidad from './ConfiguracionPrivacidad';
import { findCandidatoByID } from '../../../services/apis';

export default function Section1() {

    const user = JSON.parse(localStorage.getItem('user'));
    const idCandidato = user.codeId;

    const [search, setSearch] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const PostulacionesList = await findCandidatoByID(idCandidato);
                console.log(PostulacionesList);
                setSearch(PostulacionesList);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);

    return (
        <div className="pxp-dashboard-content-details">
            <h1>Configuración</h1>

                <div className="mt-4 mt-lg-5">
                    <div className="table-responsive">
                        <table className="table align-middle">
                            <thead></thead>
                            <tbody>
                                <ConfiguracionEmail search = {search} />
                                <ConfiguracionPassword search = {search} />
                                <>{/*<ConfiguracionDosFactores />*/}</>
                                <ConfiguracionPrivacidad />
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>

        </div>
    )
}