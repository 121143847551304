import React from 'react';
import FormOtrosConocimientos from '../../../components/otros-conocimientos/FormOtrosConocimientos';
import OtrosConocimientosList from '../../../components/otros-conocimientos/OtrosConocimientosList';

export default function CvOtrosConocimientos({search}) {

    if(!search) return null;

    return (
        <>
            <div className="mt-4 mt-lg-5">
                <div style={{position: "relative"}}>
                    <div style={{"display": "inline-block"}} >
                        <h2 style={{"float": "left"}} >Otros conocimientos &nbsp;&nbsp;</h2>
                        <button type="button" title="Añadir" data-bs-toggle="modal" data-bs-target="#otroConocimientosModal" style={{"float": "left", borderRadius: "5px", backgroundColor: "var(--pxpMainColorLight)", borderWidth: "inherit" }}><span className="fa fa-plus-circle"></span></button>
                    </div>
                </div>

                <div className="pxp-candidate-dashboard-skills mb-3">
                    <ul className="list-unstyled">
                        <OtrosConocimientosList conocimientos={search} key={search} />
                    </ul>
                </div>
            </div>

            <FormOtrosConocimientos />
        </>
    )

}