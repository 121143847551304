import React, { useState } from 'react';
import imagen from '../../../assets/images/signup-big-fig.png';
import swal from 'sweetalert';
import { useCookies } from 'react-cookie';


let urlEndPoint = process.env.REACT_APP_API_URL;
let urlDomain = process.env.REACT_APP_URL_DOMAIN;
let urlPublico = process.env.REACT_APP_URL_PUBLIC;

async function loginUser(credentials) {

    try {
        
        const responseFetch = await fetch(`${urlEndPoint}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        //alert("error");
        console.error(error);
    }
    
}

export default function Section1() {

    //const classes = useStyles();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'user']);

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await loginUser({
            mail: username,
            password: password
        });
        
        if ('accessToken' in response) {
            swal("Correcto", "Bienvenido a T-convoco", "success", {
                buttons: false,
                timer: 2000,
            })
            .then((value) => {
                setCookie('accessToken', response['accessToken'], { path: '/', domain: urlDomain, maxAge: 60 * 60 * 24 * 1 });
                setCookie('user', JSON.stringify(response['user']) , { path: '/', domain: urlDomain,maxAge: 60 * 60 * 24 * 1 });

                localStorage.setItem('accessToken', response['accessToken']);
                localStorage.setItem('user', JSON.stringify(response['user']));
                localStorage.setItem('imagen', response['user']['imagen'] );
                localStorage.setItem('cv', response['user']['cv'] );
                window.location.href = "/";
            });
        } else {
            swal("Error", "Error al autentificarte", "error");
        }
    }

    return (
        <section className="pxp-hero vh-100" style={{backgroundColor: "var(--pxpMainColorLight)"}}>
            <div className="row align-items-center pxp-sign-hero-container">
                <div className="col-xl-6 pxp-column">
                    <div className="pxp-sign-hero-fig text-center pb-100 pt-100">
                        <img src={imagen} alt="Sign up" />
                        <h1 className="mt-4 mt-lg-5">Únete a <br />nuestra familia</h1>
                    </div>
                </div>
                <div className="col-xl-6 pxp-column pxp-is-light">
                    <div className="pxp-sign-hero-form pb-100 pt-100">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-xl-7 col-xxl-5">
                                <div className="pxp-sign-hero-form-content">
                                    <h5 className="text-center">Ingresar</h5>
                                    <form className="mt-4" noValidate onSubmit={handleSubmit}>
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" id="email" placeholder="Correo" onChange={e => setUserName(e.target.value)} />
                                            <label htmlFor="pxp-signup-page-email">Correo</label>
                                            <span className="fa fa-envelope-o"></span>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <input type="password" className="form-control" id="password" placeholder="Contraseña" onChange={e => setPassword(e.target.value)} />
                                            <label htmlFor="pxp-signup-page-password">Contraseña</label>
                                            <span className="fa fa-lock"></span>
                                        </div>
                                        <a className="btn rounded-pill pxp-sign-hero-form-cta d-lg-none">Ingresar</a>
                                        <button type='submit' className="btn rounded-pill pxp-sign-hero-form-cta" style={{"width":"100%"}} >Ingresar</button>
                                        
                                        <div className="mt-4 text-center pxp-sign-hero-form-small">
                                            Aún no tengo una cuenta. <a href={`${urlPublico}/registro`}>Crear Cuenta</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}