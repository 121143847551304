import React from 'react';
import FormConocimientosHabilidadesUP from './FormConocimientosHabilidadesUP';


const ConocimientosHabilidadesModalUp = ({search}) => {
    
    if(!search) return null;
    
    return (
        <>
        {<FormConocimientosHabilidadesUP data={search} />}
        </>
    )
}

export default ConocimientosHabilidadesModalUp;