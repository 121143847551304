import React from 'react';
import buffer from 'buffer';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';

let urlPulic = process.env.REACT_APP_URL_PUBLIC;
let urlEndPoint = process.env.REACT_APP_API_URL;

async function deletePostulacion(credentials) {

    try {
        
        const responseFetch = await fetch(`${urlEndPoint}/postulaciones/${credentials}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }
    
}

{/* Texto de  */}
export default function MisPostulacionesList({postulaciones}) {

    var vigencia = "NO";
    var idEmpleoBase64 = "";

    let navigate = useNavigate(); 
    
    function routeChange(id) {
        
        let path = urlPulic+`/busqueda-trabajo?empleo=`+id;
        console.log(path);
        //navigate(path);
        window.location.href = path;

        /*window.open(
            path,
            '_blank'
        );*/
    }

    const eliminarFavorito = async (a) => {
        //e.preventDefault();
        
        swal({
            title: "¿Estas seguro?",
            text: "Una vez eliminado, ¡no podrá recuperar este item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {

                const response = await deletePostulacion(a);
            
                if ('status' in response) {
                    swal("¡Éxito!", "Se borro correctamente", "success");
                    window.location.href = "/postulaciones";
                } else {
                    swal("Error", "Ocurrio un error al eliminar", "error");
                }

            } else {
                //confirmacion = False;
            }
        });
        
    }

    return (
        <>
        {
            postulaciones.map(({id, empleoId},index)=>{
                if(empleoId){
                    
                    idEmpleoBase64 = Buffer.from(empleoId.id.toString()).toString('base64');

                    if (empleoId.vigente){ vigencia = "SI"} else { vigencia = "NO"}

                return <tr key={index}>
                    <td><input type="checkbox" className="form-check-input" /></td>
                    <td>
                        <a href="javascript:void(0)" onClick={() => routeChange(idEmpleoBase64)} >
                            <div className="pxp-candidate-dashboard-job-title">{empleoId.nombre}</div>
                            <div className="pxp-candidate-dashboard-job-location" style={{"display":"none"}}><span className="fa fa-globe me-1"></span>{empleoId.nombre}</div>
                        </a>
                    </td>
                    <td><div className="pxp-candidate-dashboard-job-company">{empleoId.compania.razonSocial}</div></td>
                    <td><div className="pxp-candidate-dashboard-job-category">{empleoId.categoria}</div></td>
                    <td><div className="pxp-candidate-dashboard-job-type">{vigencia}</div></td>
                    <td><div className="pxp-candidate-dashboard-job-date mt-1">{empleoId.publicacion.substring(0,10)}</div></td>
                    <td>
                        <div className="pxp-dashboard-table-options">
                            <ul className="list-unstyled">
                                <li ><button title="Ver anuncio" onClick={() => routeChange(idEmpleoBase64)} ><span className="fa fa-eye"></span></button></li>
                                <li ><button title="Eliminar" onClick={() => eliminarFavorito(id)} ><span className="fa fa-trash-o"></span></button></li>
                            </ul>
                        </div>
                    </td>
                </tr>;
                }
            })
        }
        </>
    );
};