import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'
import phSmall from '../../assets/images/ph-small.jpg';
import logo from '../../assets/images/Tconvoco-logo.png';
import { useCookies } from 'react-cookie';

let urlDomain = process.env.REACT_APP_URL_DOMAIN;
let urlPublico = process.env.REACT_APP_URL_PUBLIC;
let urlFile = process.env.REACT_APP_URL_FILE;

export default function Header() {
    
    const user = JSON.parse(localStorage.getItem('user'));
    const imagenStorage = localStorage.getItem('imagen');
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'user']);

    const handleLogout = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        
        removeCookie('accessToken',{ path:'/', domain: urlDomain } );
        removeCookie('user',{ path:'/', domain: urlDomain });

        //document.cookie.split(";").forEach((c) => {
        //    document.cookie = c
        //    .replace(/^ +/, "")
        //    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        //});

        //window.location.href = "/login";
        window.location.href = urlPublico;
    };

    let imagen;
    //alert(imagenStorage.length);
    if (imagenStorage.length < 5) {
        imagen = phSmall;
    } else {
        imagen = urlFile+'/'+imagenStorage;
    }

    //alert(imagen)
    //`${urlFile}/${imagen}`

    return (
        <div className="pxp-dashboard-content-header pxp-is-candidate">
                <div className="pxp-nav-trigger navbar pxp-is-dashboard d-lg-none">
                    <a role="button" data-bs-toggle="offcanvas" data-bs-target="#pxpMobileNav" aria-controls="pxpMobileNav">
                        <div className="pxp-line-1"></div>
                        <div className="pxp-line-2"></div>
                        <div className="pxp-line-3"></div>
                    </a>
                    <div className="offcanvas offcanvas-start pxp-nav-mobile-container pxp-is-dashboard pxp-is-candidate" id="pxpMobileNav">
                        <div className="offcanvas-header">
                            <div className="pxp-logo " style={{ maxWidth: "25%" }}>
                                <a href={urlPublico} className="pxp-animate"><img src={logo} alt="-" style={{ maxWidth: "50%" }} /></a>
                            </div>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <nav className="pxp-nav-mobile">
                                <ul className="navbar-nav justify-content-end flex-grow-1">
                                    <li className="pxp-dropdown-header">Admin tools</li>
                                    <li className="nav-item"><a href="candidate-dashboard.html"><span className="fa fa-home"></span>Dashboard</a></li>
                                    <li className="nav-item"><a href="candidate-dashboard-profile.html"><span className="fa fa-pencil"></span>Edit Profile</a></li>
                                    <li className="nav-item"><a href="candidate-dashboard-applications.html"><span className="fa fa-file-text-o"></span>Apllications</a></li>
                                    <li className="nav-item"><a href="candidate-dashboard-fav-jobs.html"><span className="fa fa-heart-o"></span>Favourite Jobs</a></li>
                                    <li className="nav-item"><a href="candidate-dashboard-password.html"><span className="fa fa-lock"></span>Change Password</a></li>
                                    <li className="pxp-dropdown-header mt-4">Insights</li>
                                    <li className="nav-item">
                                        <a href="candidate-dashboard-inbox.html" className="d-flex justify-content-between align-items-center">
                                            <div><span className="fa fa-envelope-o"></span>Inbox</div>
                                            <span className="badge rounded-pill">14</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="candidate-dashboard-notifications.html" className="d-flex justify-content-between align-items-center">
                                            <div><span className="fa fa-bell-o"></span>Notifications</div>
                                            <span className="badge rounded-pill">5</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <nav className="pxp-user-nav pxp-on-light">
                    <a href={`${urlPublico}/busqueda-trabajo`} className="btn rounded-pill pxp-nav-btn" target="_blank">Ver ofertas laborales</a> &nbsp; &nbsp;
                    <div className="dropdown pxp-user-nav-dropdown">
                        <a role="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                            <div className="pxp-user-nav-avatar pxp-cover" style={{backgroundImage: `url(${imagen})`}}></div>
                            <div className="pxp-user-nav-name d-none d-md-block">{user.nombre} {user.apellidoP}</div>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><a className="dropdown-item " href="/cv">Mi CV</a></li>
                            <li><a className="dropdown-item " href="/postulaciones">Mis Postulaciones</a></li>
                            <li><a className="dropdown-item " href="/actualizarfoto">Actualizar Foto</a></li>
                            <li><a className="dropdown-item " href="/actualizarcv">Subir CV</a></li>
                            <li><a className="dropdown-item " onClick={handleLogout} >Salir</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
    );
}