import React,{useState, useEffect} from 'react';
import swal from 'sweetalert';
import { findConocimientoByID } from '../../services/apis';
import ConocimientosHabilidadesModalUp from './ConocimientosHabilidadesModalUp';

let urlEndPoint = process.env.REACT_APP_API_URL;

async function deleteEventoCV(credentials) {

    try {
        
        const responseFetch = await fetch(`${urlEndPoint}/conocimientoshabilidades/${credentials}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(credentials)
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }
    
}


export default function ConocimientosHabilidadesList({conocimientos}) {

    const [search, setSearch] = useState(null);

    const [idini, setIdIni] = useState(0);
    const [idfinal, setIdFinal] = useState(null);

    const eliminar = async (a) => {
        //e.preventDefault();

        swal({
            title: "¿Estas seguro?",
            text: "Una vez eliminado, ¡no podrá recuperar este item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {

                const response = await deleteEventoCV(a);
            
                if ('status' in response) {
                    swal("¡Éxito!", "Se borro correctamente", "success");
                    window.location.href = "/cv";
                } else {
                    swal("Error", "Ocurrio un error al eliminar", "error");
                }

            } else {
                //confirmacion = False;
            }
        });        
        
    }


    useEffect( () => {

        console.log("FORMACION ID: " + idini)
        //setIdFinal(() => idini);

        // if ( (idini === null) ) return;
        // if(!idini) {setexpLaboralList(null); return null};

        async function fetchData() {
            try {

                const expList = await findConocimientoByID(idini);
                
                //console.log(expList);
                setSearch(expList);                

            } catch (e) {
                console.error(e);
            }

            

        };

        fetchData();

    },[idini]);


    return (
        <>
                {
                    conocimientos.map(({id, nombre},index)=>{
                        return <li key={index}>{nombre} <span className="fa fa-pencil" onClick={() => (setIdIni(id)) } data-bs-toggle="modal" data-bs-target="#conocimientoHabilidadesUPModal" ></span> <span className="fa fa-trash-o" onClick={() => eliminar(id)} ></span></li>;
                    })
                }
            <ConocimientosHabilidadesModalUp search={search} />
        </>
    );
};