import React from 'react';
import { useParams } from 'react-router-dom';
import SectionCV from './sections/SectionCV';

export default function UploadCv() {

    //const { id } = useParams();
    //console.log("UPLOAD "+id);

    return (
        <>
        <SectionCV  />
        </>
    );
}