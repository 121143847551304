import { helpHttp } from "../helpers/helpHttp";

let urlEndPoint = process.env.REACT_APP_API_URL;

export async function findCandidatoByParameters ( puesto, ubicacion, tipoEmpleo, modalidadEmpleo, companiaID ){

        let busquedaURLPars = `${urlEndPoint}/empleos/-/find`;
        let optionsBody;
        
        if(companiaID){
            optionsBody = {
                companiaId: companiaID
            }
        } else {
            optionsBody = {
                nombre : `${puesto}`,
                provincia : `${ubicacion}`,
                tipoEmpleoEnum: {
                    id : `${tipoEmpleo}`
                },
                modalidadEmpleoEnum: {
                    id : `${modalidadEmpleo}`
                },
                companiaId: companiaID
            }
        }

        let optionsPars = {
            headers: { type: 'normal' },
            body: optionsBody
        }

        console.log("CONSULTA SERVICIOS - JOBS")
        console.log(busquedaURLPars);
        const [busquedaResPar] = await Promise.all([helpHttp().patch(busquedaURLPars, optionsPars)]);

        return busquedaResPar;

}


export async function findCandidatoByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/postulantes/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}


export async function findExpLaboralByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/experiencialaboral/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findExpLaboralByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/experiencialaboral/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findFormacionAcademicaByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/formacionacademica/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findFormacionAcademicaByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/formacionacademica/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function insertExperienciaLaboralByParameters ( idpostulante, nombre, empresa, descripcion, fechaini, fechafin ){

    let urlAPI = `${urlEndPoint}/experiencialaboral/`;
    let optionsBody;
    
        optionsBody = {
            id : null,
            postulanteId : `${idpostulante}`,
            nombre: `${nombre}`,
            compania: `${empresa}`,
            descripcion: `${descripcion}`,
            fechaIngreso: `${fechaini}`,
            fechaTermino: `${fechafin}`,
        }
    

    let optionsPars = {
        body: optionsBody
    }

    console.log("INSERTAR EXPERIENCIA LABORAL")
    console.log(urlAPI);
    const [insertarExperiencia] = await Promise.all([helpHttp().post(urlAPI, optionsPars)]);

    return insertarExperiencia;

}


export async function findIdiomaByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/idiomas/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findIdiomaByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/idiomas/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}


export async function findConocimientoByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/conocimientoshabilidades/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findConocimientoByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/conocimientoshabilidades/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}


export async function findOtrosConocimientosByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/otrosconocimientos/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findOtrosConocimientosByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/otrosconocimientos/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findListaByCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/postulaciones/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findListaFavoritosByCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/postulaciones/favoritos/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}