import React from 'react';
import { useFormREP } from '../../hooks/useFormREP';
import Message from '../messages/message';

const initialForm = {
    id: null,
    puesto:"",
    compania: "",
    fechaini: "",
    fechafin: "",
    descripcion: ""
};

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.puesto.trim()){
        errors.puesto = "El campo puesto es requerido";
    } else if( !regexNombres.test(form.puesto) ) {
        errors.puesto = "El campo puesto solo acepta letras y espacios";
    }

    if(!form.compania.trim()){
        errors.compania = "El campo empresa es requerido";
    } else if(!regexNombres.test(form.compania)){
        errors.compania = "El campo empresa solo acepta letras y espacios";
    }

    if (!form.fechaini.trim()){
        errors.fechaini = "La campo fecha de inicio es requerida";
    }

    if (!form.fechafin.trim()){
        errors.fechafin = "La campo fecha fin es requerida";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

const FormExperienciaLaboral = () => {
    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormREP(initialForm, validationsForms);
    

    return (
        <div className="modal fade" id="experienciaLaboralModal" tabIndex="-1" aria-labelledby="experienciaLaboralModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="experienciaLaboralModalLabel">Experiencia Laboral</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="puesto" className="col-form-label">Puesto</label>
                                            <input type="hidden" id="id" name='id' readOnly />
                                            <input type="text" className="form-control" id="puesto" name='puesto' onBlur={handleBlur} onChange={handleChange} value={form.puesto} required />
                                            {errors.puesto && <p style={styles} > {errors.puesto}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="compania" className="col-form-label">Compañia / Empresa</label>
                                            <input type="text" className="form-control" id="compania" name='compania' onBlur={handleBlur} onChange={handleChange} value={form.compania} required />
                                            {errors.compania && <p style={styles} > {errors.compania}</p>}
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="fechaini" className="col-form-label">Fecha de Inicio</label>
                                            <input type="text" className="form-control" id="fechaini" name='fechaini' onBlur={handleBlur} onChange={handleChange} value={form.fechaini} required />
                                            {errors.fechaini && <p style={styles} > {errors.fechaini}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="fechafin" className="col-form-label">Fecha de Termino</label>
                                            <input type="text" className="form-control" id="fechafin" name='fechafin' onBlur={handleBlur} onChange={handleChange} value={form.fechafin} required />
                                            {errors.fechafin && <p style={styles} > {errors.fechafin}</p>}
                                        </div>
                                        
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label htmlFor="descripcion" className="col-form-label">Descripción de responsabilidades</label>
                                            <textarea className="form-control" id="descripcion" name='descripcion' rows={2} style={{height: 130}} onBlur={handleBlur} onChange={handleChange} value={form.descripcion} ></textarea>
                                            {errors.descripcion && <p style={styles} > {errors.descripcion}</p>}
                                        </div>
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se añadió de manera correcta." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormExperienciaLaboral;