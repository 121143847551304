import React,{useState, useEffect} from 'react';
import { findExpLaboralByIDCandidato } from '../../services/apis';
import FormExperienciaLaboralUP from './FormExperienciaLaboralUP';

const ExperienciaLaboralModalUp = ({search}) => {
    
    if(!search) return null;
    
    return (
        <>
        {<FormExperienciaLaboralUP data={search} />}
        </>
    )
}

export default ExperienciaLaboralModalUp;