import React from 'react';
import { Link } from 'react-router-dom';
import phSmall from '../../assets/images/ph-small.jpg';
import logo from '../../assets/images/Tconvoco-logo.png';
import { useCookies } from 'react-cookie';

let urlDomain = process.env.REACT_APP_URL_DOMAIN;
let urlPublico = process.env.REACT_APP_URL_PUBLIC;
let urlFile = process.env.REACT_APP_URL_FILE;

const activeClass = (route) => { return window.location.pathname === route ? "pxp-active" : null }

export default function MenuLeft() {

    const user = JSON.parse(localStorage.getItem('user'));
    const imagenStorage = localStorage.getItem('imagen');
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'user']);

    const handleLogout = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");

        removeCookie('accessToken',{ path:'/', domain: urlDomain } );
        removeCookie('user',{ path:'/', domain: urlDomain });

        //document.cookie.split(";").forEach((c) => {
        //    document.cookie = c
        //    .replace(/^ +/, "")
        //    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        //});

        //window.location.href = "/login";
        window.location.href = urlPublico;
    };

    let imagen;
    if (imagenStorage.length < 5) {
        imagen = phSmall;
    } else {
        imagen = urlFile+'/'+imagenStorage;
    }
    //`${urlFile}/${imagen}`

    return (
        <div className="pxp-dashboard-side-panel d-none d-lg-block">
            <div className="pxp-logo " >
                <a href={urlPublico} className="pxp-animate"><img src={logo} alt="-" style={{ maxWidth: "80%" }} /></a>
            </div>

            <nav className="mt-3 mt-lg-4 d-flex justify-content-between flex-column pb-100">
                <div className="text-center">
                    <a href="/actualizarfoto"><img src={`${imagen}`} className="rounded" alt="Perfil" style={{"width":"160px", "height":"160px"}} /></a>
                    <span className="fa fa-plus-circle d-none"></span>
                </div>
                <ul className="list-unstyled mt-lg-4">
                    <li className={activeClass("/")} ><a href="/"><span className="fa fa-home"></span>Mi Cuenta</a></li>
                    <li className={activeClass("/cv")}><a href="/cv"><span className="fa fa-pencil"></span>Mi CV</a></li>
                    <li className={activeClass("/postulaciones")} ><a href="/postulaciones"><span className="fa fa-file-text-o"></span>Mis Postulaciones</a></li>
                    <li className={activeClass("/favoritos")} ><a href="/favoritos"><span className="fa fa-heart-o"></span>Mis Favoritos</a></li>
                    <li className={activeClass("/configuracion")} ><a href="/configuracion"><span className="fa fa-lock"></span>Configuración</a></li>
                </ul>
            </nav>

            <nav className="pxp-dashboard-side-user-nav-container pxp-is-candidate">
                <div className="pxp-dashboard-side-user-nav">
                    <div className="dropdown pxp-dashboard-side-user-nav-dropdown dropup">
                        <a role="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                            <div className="pxp-dashboard-side-user-nav-avatar pxp-cover" style={{backgroundImage: `url(${imagen})`}}></div>
                            <div className="pxp-dashboard-side-user-nav-name">{user.nombre} {user.apellidoP}</div>
                        </a>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" onClick={handleLogout} >Salir</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}