import React,{useState, useEffect} from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import SobreMi from './SobreMi';
import CvExperienciaLaboral from './CvExperienciaLaboral';
import CvFormacionAcademica from './CvFormacionAcademica';
import CvIdiomas from './CvIdiomas';
import CvConocmientosHabilidades from './CvConocimientosHabilidades';
import CvOtrosConocimientos from './CvOtrosConocimientos';
import CvDatosPersonales from './CvDatosPersonales';
import { findCandidatoByID, findConocimientoByIDCandidato, findExpLaboralByIDCandidato, findFormacionAcademicaByIDCandidato, findIdiomaByIDCandidato, findOtrosConocimientosByIDCandidato } from '../../../services/apis';

export default function Section1() {

    const user = JSON.parse(localStorage.getItem('user'));
    const idCandidato = user.codeId;
    
    const [search, setSearch] = useState(null);
    const [userList, setUserList] = useState(null);
    const [expLaboralList, setexpLaboralList] = useState(null);
    const [forAcademicaList, setforAcademicaList] = useState(null);
    const [idiomaList, setIdiomaList] = useState(null);
    const [conocimientoList, setConocimientoList] = useState(null);
    const [otroconocimientoList, setOtroConocimientoList] = useState(null);
    const [posts, setPosts] = useState([]);

    useEffect( () => {

        //window.scrollTo(0, 0); // FORZA TOP

        //alert("Hola");
        //if ( (search === null) ) return;

        async function fetchData() {
            try {

                const jobsList = await findCandidatoByID(idCandidato);
                
                console.log(jobsList);
                setUserList(jobsList);

            } catch (e) {
                console.error(e);
            }

            try {

                const expList = await findExpLaboralByIDCandidato(idCandidato);
                
                console.log(expList);
                setexpLaboralList(expList);

            } catch (e) {
                console.error(e);
            }
            
            // FORMACION
            try {

                const formList = await findFormacionAcademicaByIDCandidato(idCandidato);
                
                console.log(formList);
                setforAcademicaList(formList);

            } catch (e) {
                console.error(e);
            }

            // IDIOMA
            try {

                const IdiList = await findIdiomaByIDCandidato(idCandidato);
                
                console.log(IdiList);
                setIdiomaList(IdiList);

            } catch (e) {
                console.error(e);
            }

            // CONOCIMIENTOS Y HABILIDADES
            try {

                const ConoList = await findConocimientoByIDCandidato(idCandidato);
                
                console.log(ConoList);
                setConocimientoList(ConoList);

            } catch (e) {
                console.error(e);
            }

            // OTROS CONOCIMIENTOS
            try {

                const otroList = await findOtrosConocimientosByIDCandidato(idCandidato);
                
                console.log(otroList);
                setOtroConocimientoList(otroList);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);


    console.log("Section1");
    //console.log(userList);

    return (
        <div className="pxp-dashboard-content-details">
            
            <div className="row">
                <div className="col-lg-7 col-xl-8 col-xxl-9">
                        
                        <SobreMi search = {userList} />

                        <h1>Mi CV</h1>

                        <CvExperienciaLaboral search = {expLaboralList} />
                        <CvFormacionAcademica search={forAcademicaList} />

                        <CvIdiomas search={idiomaList} />
                        <CvConocmientosHabilidades search={conocimientoList} />
                        <CvOtrosConocimientos search={otroconocimientoList} />

                        <div className="mt-4 mt-lg-5 d-lg-none">
                            <button className="btn rounded-pill pxp-section-cta">Guardar perfil</button>
                        </div>
                        
                </div>
                <div className="col-lg-5 col-xl-4 col-xxl-3">
                        
                        <CvDatosPersonales search = {userList} />

                </div>
            </div>

        </div>
    )
}