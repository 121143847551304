import React from 'react';
import { useFormRID } from '../../hooks/useFormRID';
import Message from '../messages/message';

const initialForm = {
    id: null,
    nombreIdioma:"",
    nivelEscrito: 1,
    nivelOral: 1
};

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[.A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.nombreIdioma.trim()){
        errors.nombreIdioma = "El campo carrera es requerido";
    } else if( !regexNombres.test(form.nombreIdioma.trim()) ) {
        errors.nombreIdioma = "El campo carrera solo acepta letras y espacios";
    }    

    /*if (!form.nivelEscrito.trim()){
        errors.nivelEscrito = "La campo fecha de inicio es requerida";
    }

    if (!form.nivelOral.trim()){
        errors.nivelOral = "La campo fecha fin es requerida";
    }*/

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

const FormIdioma = () => {
    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormRID(initialForm, validationsForms);
    

    return (
        <div className="modal fade" id="IdiomaModal" tabIndex="-1" aria-labelledby="IdiomaModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="IdiomaModalLabel">Idioma</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        
                                        <div className="col-sm-6">
                                            <label htmlFor="nombreIdioma" className="col-form-label">Nombre Idioma</label>
                                            <input type="text" className="form-control" id="nombreIdioma" name='nombreIdioma' onBlur={handleBlur} onChange={handleChange} value={form.nombreIdioma} required />
                                            {errors.nombreIdioma && <p style={styles} > {errors.nombreIdioma}</p>}
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        
                                        <div className="col-sm-6">
                                            <label htmlFor="nivelEscrito" className="col-form-label">Nivel Escrito</label>
                                            <select className="form-control" id="nivelEscrito" name='nivelEscrito' onBlur={handleBlur} onChange={handleChange} required >
                                                <option value="1" defaultValue>Básico</option>
                                                <option value="2">Intermedio</option>
                                                <option value="3">Avanzado</option>
                                                <option value="4">Nativo</option>
                                            </select>
                                            {errors.nivelEscrito && <p style={styles} > {errors.nivelEscrito}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="nivelOral" className="col-form-label">Nivel Oral</label>
                                            <select className="form-control" id="nivelOral" name='nivelOral' onBlur={handleBlur} onChange={handleChange} required >
                                                <option value="1" defaultValue>Básico</option>
                                                <option value="2">Intermedio</option>
                                                <option value="3">Avanzado</option>
                                                <option value="4">Nativo</option>
                                            </select>
                                            {errors.nivelOral && <p style={styles} > {errors.nivelOral}</p>}
                                        </div>

                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se añadió de manera correcta." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormIdioma;