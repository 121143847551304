import React from 'react';
import FormIdioma from '../../../components/idioma/FormIdioma';
import IdiomaList from '../../../components/idioma/IdiomaList';

export default function CvIdiomas({search}) {

    if(!search) return null;

    return (
        <>
            <div className="mt-4 mt-lg-5">
                <div style={{position: "relative"}}>
                    <div style={{"display": "inline-block"}} >
                        <h2 style={{"float": "left"}}>¿Qué idiomas hablas? &nbsp;&nbsp;</h2>
                        <button type="button" title="Añadir" data-bs-toggle="modal" data-bs-target="#IdiomaModal" style={{"float": "left", borderRadius: "5px", backgroundColor: "var(--pxpMainColorLight)", borderWidth: "inherit" }}><span className="fa fa-plus-circle"></span></button>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table align-middle">
                        <tbody>
                            <IdiomaList idioma={search} key={search} />
                        </tbody>
                    </table>
                </div>
                <FormIdioma />
            </div>
        </>
    )

}