import React from 'react';
import { useFormROC } from '../../hooks/useFormROC';
import Message from '../messages/message';

const initialForm = {
    id: 1,
    conocimiento:""
};

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[.A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.conocimiento.trim()){
        errors.conocimiento = "El campo carrera es requerido";
    } else if( !regexNombres.test(form.conocimiento.trim()) ) {
        errors.conocimiento = "El campo carrera solo acepta letras y espacios";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

const FormOtrosConocimientos = () => {
    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormROC(initialForm, validationsForms);
    

    return (
        <div className="modal fade" id="otroConocimientosModal" tabIndex="-1" aria-labelledby="otroConocimientosModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="otroConocimientosModalLabel">Otros Conocimientos</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="conocimiento" className="col-form-label">Otros conocimientos</label>
                                            <input type="text" className="form-control" id="conocimiento" name='conocimiento' onBlur={handleBlur} onChange={handleChange} value={form.conocimiento} required />
                                            {errors.conocimiento && <p style={styles} > {errors.conocimiento}</p>}
                                        </div>
                                        
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se añadió de manera correcta." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormOtrosConocimientos;