import React from 'react';
import { useFormRFA } from '../../hooks/useFormRFA';
import Message from '../messages/message';

const initialForm = {
    id: null,
    carrera:"",
    nivelestudios: "",
    institucion:"",
    fechaini: "",
    fechafin: "",
    descripcion: ""
};

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[.A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.carrera.trim()){
        errors.carrera = "El campo carrera es requerido";
    } else if( !regexNombres.test(form.carrera.trim()) ) {
        errors.carrera = "El campo carrera solo acepta letras y espacios";
    }

    if(!form.nivelestudios.trim()){
        errors.nivelestudios = "El campo estado es requerido";
    } else if(!regexNombres.test(form.nivelestudios.trim())){
        errors.nivelestudios = "El campo estado solo acepta letras y espacios";
    }

    if(!form.institucion.trim()){
        errors.institucion = "El campo institución es requerido";
    } else if(!regexNombres.test(form.institucion.trim())){
        errors.institucion = "El campo institución solo acepta letras y espacios";
    }
    

    if (!form.fechaini.trim()){
        errors.fechaini = "La campo fecha de inicio es requerida";
    }

    if (!form.fechafin.trim()){
        errors.fechafin = "La campo fecha fin es requerida";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

const FormFormacionAcademica = () => {
    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormRFA(initialForm, validationsForms);
    

    return (
        <div className="modal fade" id="formacionAcademicaModal" tabIndex="-1" aria-labelledby="formacionAcademicaModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="formacionAcademicaModalLabel">Formación Academica</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="carrera" className="col-form-label">Carrera profesional</label>
                                            <input type="text" className="form-control" id="carrera" name='carrera' onBlur={handleBlur} onChange={handleChange} value={form.carrera} required />
                                            {errors.carrera && <p style={styles} > {errors.carrera}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="nivelestudios" className="col-form-label">Nivel de estudios</label>
                                            <input type="text" className="form-control" id="nivelestudios" name='nivelestudios' onBlur={handleBlur} onChange={handleChange} value={form.nivelestudios} required />
                                            {errors.nivelestudios && <p style={styles} > {errors.nivelestudios}</p>}
                                        </div>

                                        <div className="col-sm-12">
                                            <label htmlFor="institucion" className="col-form-label">Institución</label>
                                            <input type="text" className="form-control" id="institucion" name='institucion' onBlur={handleBlur} onChange={handleChange} value={form.institucion} required />
                                            {errors.institucion && <p style={styles} > {errors.institucion}</p>}
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label htmlFor="fechaini" className="col-form-label">Fecha de Inicio</label>
                                            <input type="text" className="form-control" id="fechaini" name='fechaini' onBlur={handleBlur} onChange={handleChange} value={form.fechaini} required />
                                            {errors.fechaini && <p style={styles} > {errors.fechaini}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label htmlFor="fechafin" className="col-form-label">Fecha de Termino</label>
                                            <input type="text" className="form-control" id="fechafin" name='fechafin' onBlur={handleBlur} onChange={handleChange} value={form.fechafin} required />
                                            {errors.fechafin && <p style={styles} > {errors.fechafin}</p>}
                                        </div>
                                        
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se añadió de manera correcta." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormFormacionAcademica;