import React from 'react';
import ExperienciaLaboralList from '../../../components/experiencia/ExperienciaLaboralList';
import FormExperienciaLaboral from '../../../components/experiencia/FormExperienciaLaboral';


export default function ExperienciaLaboral({search}) {

    if(!search) return null;

    return (
        <>
            <div className="mt-4 mt-lg-5">
                <div style={{position: "relative"}}>
                    <div style={{"display": "inline-block"}} >
                        <h2 style={{"float": "left"}}>Experiencia Laboral &nbsp;&nbsp;</h2>
                        <button type="button" title="Añadir" data-bs-toggle="modal" data-bs-target="#experienciaLaboralModal" style={{"float": "left", borderRadius: "5px", backgroundColor: "var(--pxpMainColorLight)", borderWidth: "inherit" }}><span className="fa fa-plus-circle"></span></button>
                    </div>
                </div>
                
                <div className="table-responsive">
                    <table className="table align-middle">
                        <thead></thead>
                        <tbody>
                            <ExperienciaLaboralList experiencia={search} key={search}/>
                        </tbody>
                        <tfoot></tfoot>
                    </table>
                </div>
            </div>

            <FormExperienciaLaboral />
        </>
    )

}