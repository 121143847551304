import React from 'react';
import FormDatosPersonales from '../../../components/datos-personales/FormDatosPersonales';

export default function CvDatosPersonales({search}) {

    if(!search) return null;

    return (
        <>
            <div className="pxp-single-candidate-side-panel mt-5 mt-lg-0">
                <div style={{position: "relative"}}>
                    <button type="button" title="Edit" data-bs-toggle="modal" data-bs-target="#datosPersonalesModal" style={{position: "absolute", top: -5, right: "-12%", borderRadius: "5px", backgroundColor: "var(--pxpMainColorLight)", borderWidth: "inherit" }}><span className="fa fa-pencil"></span></button>
                </div>
                <div>
                    <div className="pxp-single-candidate-side-info-label pxp-text-light">Nombres</div>
                    <div className="pxp-single-candidate-side-info-data">{search.nombre}</div>
                </div>
                <div className="mt-4">
                    <div className="pxp-single-candidate-side-info-label pxp-text-light">Apellidos</div>
                    <div className="pxp-single-candidate-side-info-data">{search.apellidoPaterno} {search.apellidoMaterno}</div>
                </div>

                <div className="mt-4">
                    <div className="pxp-single-candidate-side-info-label pxp-text-light">Correo</div>
                    <div className="pxp-single-candidate-side-info-data">{search.mail}</div>
                </div>

                <div className="mt-4">
                    <div className="pxp-single-candidate-side-info-label pxp-text-light">Teléfono</div>
                    <div className="pxp-single-candidate-side-info-data">{search.telefono}</div>
                </div>
                
                <div className="mt-4">
                    <div className="pxp-single-candidate-side-info-label pxp-text-light">Dirección</div>
                    <div className="pxp-single-candidate-side-info-data">{search.direccion}</div>
                </div>                
            </div>
            
            <FormDatosPersonales datos={search} key={search} />

            <br />
            <div className="pxp-single-candidate-side-panel mt-5 mt-lg-0 text-center">
                <div style={{position: "relative"}}>
                    <a href="/actualizarcv" className="btn rounded-pill pxp-nav-btn" >&nbsp; Subir CV &nbsp;</a>
                </div>               
            </div>
        </>
    )

}