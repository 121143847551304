import React,{useState, useEffect} from 'react';
import { findListaByCandidato } from '../../../services/apis';
import MisPostulaciones from './MisPostulaciones';

export default function Section1() {

    const user = JSON.parse(localStorage.getItem('user'));
    const idCandidato = user.codeId;

    const [search, setSearch] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const PostulacionesList = await findListaByCandidato(idCandidato);
                console.log(PostulacionesList);
                setSearch(PostulacionesList);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);

    return (
        <div className="pxp-dashboard-content-details">
            
            <h1>Mis Postulaciones</h1>
            <p className="pxp-text-light">Lista detallada de mis postulaciones.</p>

            <div className="mt-4 mt-lg-5">
                <div className="row justify-content-between align-content-center" style={{"display":"none"}}>
                    <div className="col-auto order-2 order-sm-1">
                        <div className="pxp-candidate-dashboard-jobs-bulk-actions mb-3">
                            <select className="form-select">
                                <option>Acciones masivas</option>
                                <option>Borrar</option>
                            </select>
                            <button className="btn ms-2">Aplicar</button>
                        </div>
                    </div>
                    <div className="col-auto order-1 order-sm-2">
                        <div className="pxp-candidate-dashboard-jobs-search mb-3">
                            <div className="pxp-candidate-dashboard-jobs-search-results me-3">16 trabajos</div>
                            <div className="pxp-candidate-dashboard-jobs-search-search-form">
                                <div className="input-group">
                                    <span className="input-group-text"><span className="fa fa-search"></span></span>
                                    <input type="text" className="form-control" placeholder="Buscar empleos..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MisPostulaciones search = {search} />

            </div>
        </div>
    );
};