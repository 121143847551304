import React from 'react';
import FormIdiomaUP from './FormIdiomaUP';


const IdiomaModalUp = ({search}) => {
    
    if(!search) return null;
    
    return (
        <>
        {<FormIdiomaUP data={search} />}
        </>
    )
}

export default IdiomaModalUp;