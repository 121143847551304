import React,{useState, useEffect} from 'react';
import { findListaByCandidato, findListaFavoritosByCandidato } from '../../../services/apis';
import InicioDashboard from './InicioDashboard';
import './Section1.css';

export default function Section1() {

    const [search, setSearch] = useState(null);

    const [jobList, setJobList] = useState(null);
    const [jobListFav, setJobListFav] = useState(null);
    const [jobDetail, setJobDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'));
    //console.log("STORAGE " + user.codeId);
    const idCandidato = user ? user.codeId : null;
    //console.log("CONST: " + idCandidato);

    useEffect( async () => {

        window.scrollTo(0, 0); // FORZA TOP

        try {
            const res = await findListaByCandidato(idCandidato);
            console.log(res);
            setJobList(res);

        } catch (e) {
            console.error(e);
        }

        try {
            const resfav = await findListaFavoritosByCandidato(idCandidato);
            console.log(resfav);
            setJobListFav(resfav);

        } catch (e) {
            console.error(e);
        }

    },[search]);

    
    return (
        <div className="pxp-dashboard-content-details">
                <h1>Hola: {user.nombre ? user.nombre : null} {user.apellidoP ? user.apellidoP : null}</h1>
                <p className="pxp-text-light">Bienvenido a TConvoco!</p>

                <InicioDashboard jobList={ jobList } jobListFav = {jobListFav} />
                
            </div>
    );
};