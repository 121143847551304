import React from 'react';
import { Link } from 'react-router-dom';
import Section1 from './sections/Section1';

export default function Favoritos() {
    return (
        <>
        <Section1/>
        </>
    );
}