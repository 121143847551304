import React from 'react';
import { useParams } from 'react-router-dom';
import Section1 from './sections/Section1';

export default function UploadImagen() {

    //const { id } = useParams();
    //console.log("UPLOAD "+id);

    return (
        <>
        <Section1  />
        </>
    );
}