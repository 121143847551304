import React,{useState, useEffect} from 'react';
import { findListaFavoritosByCandidato } from '../../../services/apis';
import MisFavoritos from './MisFavoritos';

export default function Section1() {

    const user = JSON.parse(localStorage.getItem('user'));
    const idCandidato = user.codeId;

    const [search, setSearch] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // LISTA FAVORITOS
            try {
                const FavoritosList = await findListaFavoritosByCandidato(idCandidato);
                console.log(FavoritosList);
                setSearch(FavoritosList);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);

    return (
        <div className="pxp-dashboard-content-details">
            <h1>Mis Favoritos</h1>

            <form>
                <MisFavoritos search = {search} />
            </form>

        </div>
    )
};