import React from 'react';
import MisPostulacionesList from '../../../components/mis-postulaciones/MisPostulacionesList';


export default function MisPostulaciones({search}) {

    if(!search) return null;

    return (
        <>
            <div className="table-responsive">
                        <table className="table table-hover align-middle">
                            <thead>
                                <tr>
                                    <th className="pxp-is-checkbox" style={{width: '1%'}}><input type="checkbox" className="form-check-input" /></th>
                                    <th style={{width: '40%'}}>Puesto</th>
                                    <th style={{width: '15%'}}>Compañia</th>
                                    <th style={{width: '12%'}}>Categoría</th>
                                    <th style={{width: '10%'}}>Vigencia</th>
                                    <th style={{width: '10%'}}>Fecha</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <MisPostulacionesList  postulaciones={search} key={search} />
                            </tbody>
                            <tfoot></tfoot>
                        </table>

                        <div className="row mt-4 mt-lg-5 justify-content-between align-items-center" style={ { "display": "none" } }>
                            <div className="col-auto">
                                <nav className="mt-3 mt-sm-0" aria-label="Jobs list pagination">
                                    <ul className="pagination pxp-pagination">
                                        <li className="page-item active" aria-current="page">
                                            <span className="page-link">1</span>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

            </div>
                
        </>
    )

}