import React from 'react';
import Categories from '../../../components/categories/Categories';

export default function InicioDashboard({search, jobList, jobListFav }) {

    const ArrFavorito = [];

    console.log("LISTAs - PREV");
    if(!jobList) return null;
    if(!jobListFav) return null;

    /*jobList.forEach(({id,  favorito, expectativaSalarial },index)=>{
        if (favorito == 1) {
            console.log("FAV: "+index);
            ArrFavorito.push(favorito);
        }
    });*/

    const contarTotal = jobList.length;
    //const contarFavorito = ArrFavorito.length;
    const contarFavorito = jobListFav.length;

    return(
                <div className="row mt-4 mt-lg-5 align-items-center" >
                    <div className="col-sm-6 col-xxl-3">
                        <div className="pxp-dashboard-stats-card bg-primary bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-primary">
                                <span className="fa fa-file-text-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">{contarTotal}</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light"><a href='/postulaciones'>Postulaciones</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="pxp-dashboard-stats-card bg-success bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-success">
                                <span className="fa fa-user-circle-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">{contarFavorito}</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light"><a href='/favoritos'>Favoritos</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3 d-lg-none">
                        <div className="pxp-dashboard-stats-card bg-warning bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-warning">
                                <span className="fa fa-envelope-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">14</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light">Mensajes</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3 d-lg-none">
                        <div className="pxp-dashboard-stats-card bg-danger bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-danger">
                                <span className="fa fa-bell-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">5</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light">Notificaciones</div>
                            </div>
                        </div>
                    </div>
                </div>
    );
};